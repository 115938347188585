import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  inject,
} from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { NotificationStatus, NotificationsStore } from '../notifications.store';
import { NotificationTileComponent } from './notification-tile';
import { marker as t } from '@jsverse/transloco-keys-manager/marker';
import { CdkTransformPipe } from '@cca-common/cdk';
import { SpinnerComponent } from '@cca/ui';
import { filter, take } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { IconComponent } from '@cca/ui';
import { DateGroup } from '@cca-infra/notification-service/v1';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'cca-notifications-panel',
  imports: [
    TranslocoModule,
    MatSidenavModule,
    MatSidenavModule,
    MatIconModule,
    MatTabsModule,
    MatButtonModule,
    MatExpansionModule,
    TranslocoModule,
    SpinnerComponent,
    NotificationTileComponent,
    CdkTransformPipe,
    IconComponent,
  ],
  templateUrl: './notifications-panel.component.html',
  styleUrls: ['./notifications-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope('notifications')],
})
export class NotificationsPanelComponent {
  private drawer = inject(MatDrawer);

  public notificationsStore = inject(NotificationsStore);
  public NotificationStatus = NotificationStatus;

  @ViewChild(MatTabGroup) matTabGroup: MatTabGroup | undefined;

  constructor() {
    // when drawer is opened
    this.drawer.openedChange
      .pipe(
        filter((x) => x),
        take(1),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.notificationsStore.getNextNotifications(NotificationStatus.All);
        this.notificationsStore.getNextNotifications(NotificationStatus.Unread);
      });
  }

  notificationTitle = (entityType: DateGroup) => {
    switch (entityType) {
      case DateGroup.Today:
        return t('notifications.dateGroup.today');
      case DateGroup.Week:
        return t('notifications.dateGroup.week');
      case DateGroup.Older:
        return t('notifications.dateGroup.older');
    }

    return entityType;
  };

  trackNotificationList = (list: unknown) => {
    return list;
  };
}
